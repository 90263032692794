<template>

  <div>
    <div class="card card-custom card-stretch gutter-b">
      <!--begin::Header-->
      <div class="card-header border-0 py-5">
        <h3 class="card-title align-items-start flex-column pl-2">

        </h3>
        <div class="card-toolbar">
          <a
            href="#"
            class="btn btn-primary font-weight-bolder font-size-sm mr-2 my-2"
            @click="uploadSectionsExcelClicked"
            ><i class="menu-icon flaticon2-file" style="font-size: 1.0em;"></i>{{$t('PAGES.EDUCATIONS.IMPORT_SECTIONS')}}</a
          >

          <a
            href="#"
            class="btn btn-primary font-weight-bolder font-size-sm mr-2 my-2"
            @click="createFacultyClicked"
            ><i class="menu-icon flaticon2-plus" style="font-size: 1.0em;"></i>{{$t('PAGES.EDUCATIONS.NEW_FACULTY')}}</a
          >

        </div>
      </div>
      <div class="card-body pt-0 table-responsive">
        <b-table
          id="faculty-table"
          :items="ajaxPagination"
          :fields="headers"
          :filter="filters"
          :per-page="perPage"
          :current-page="currentPage"
          head-variant="light"
          class="table-striped"
          ref="facultyTable"
          @filtered="onFiltered"
          @sort-changed="onSortChanged"
        >
          <template #cell(actions)="data">
            <div class='d-flex justify-content-end'>
              <a
                href="#"
                class="btn btn-icon btn-light btn-sm mx-3"
                @click.prevent="editFacultyClicked(data.item)"
              >
                <span class="svg-icon svg-icon-md svg-icon-primary">
                  <!--begin::Svg Icon-->
                  <inline-svg src="/assets/svg/Write.svg"></inline-svg>
                  <!--end::Svg Icon-->
                </span>
              </a>
              <a
                href="#"
                class="btn btn-icon btn-light btn-sm mr-4"
                @click.prevent="deleteFacultyClicked(data.item)"
              >
                <span class="svg-icon svg-icon-md svg-icon-primary">
                  <!--begin::Svg Icon-->
                  <inline-svg src="/assets/svg/Trash.svg"></inline-svg>
                  <!--end::Svg Icon-->
                </span>
              </a>
            </div>
          </template>
        </b-table>
      </div>
    </div>
    <input hidden type="file" id="excelFile" ref="excelFile" accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel, .xslx, .xls" v-on:change="handleExcelFileUpload()" />
  </div>

</template>

<script>
import axios from 'axios';
import { mapGetters } from 'vuex';

import swal from 'sweetalert2';

import { toasts } from '@/core/mixins/toastr-helper.mixin.js';


export default {
  name: 'faculty-table',
  props: [],
  emits: ['createFacultyClicked', 'editFacultyClicked', 'deleteFacultyClicked'],
  components: {

  },
  mixins: [ toasts ],
  computed: {
    ...mapGetters(['currentCompanyId', 'currentPeriodId', 'companies', 'sid'])
  },
  watch: {
    currentPeriodId(newValue) {
      if (newValue) {

      }
    }
  },
  mounted() {

  },
  methods: {

    handleExcelFileUpload() {
      this.file = this.$refs.excelFile.files[0];

      if (this.file.size > (50000000)) {
        this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('PAGES.FILES.MUST_BE_LESS'));
        return;
      }

      this.uploadFile();

      this.file = this.$refs.excelFile.value = null;
    },

    uploadSectionsExcelClicked() {
      this.$refs.excelFile.click();
    },

    uploadFile() {
      const loader = this.$loading.show();

      let formData = new FormData();
      formData.append('file', this.file);
      formData.append('file_source', 'file');
      formData.append('file_type', 'FILE_OTHER');
      formData.append('company_id', this.currentCompanyId);
      formData.append('period_id', this.currentPeriodId);

      axios
        .post('/fileupload', formData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        })
        .then(async res => {

          if (res.status === 201) {
            try {
              const file_obj = res.data.fileobjs[0];
              await axios.post(`/education/import_sections`, { file_id: file_obj.file_id });
              loader && loader.hide();
              this.refresh();
            }
            catch (err) {
              console.error('import error', err);
              this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('COMMON.AN_ERROR_OCCURED'));
              loader && loader.hide();
            }
          }
          else {
            loader && loader.hide();
          }

          this.toastr('success', this.$t('COMMON.OK'), this.$t('PAGES.FILES.UPLOADED'));
        })
        .catch(err => {
          loader && loader.hide();
          console.error(err);
          this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('COMMON.AN_ERROR_OCCURED'));
        });
    },

    createFacultyClicked() {
      this.$emit('createFacultyClicked');
    },

    editFacultyClicked(faculty) {
      this.$emit('editFacultyClicked', faculty);
    },

    deleteFacultyClicked(faculty) {
      this.$emit('deleteFacultyClicked', faculty);
    },

    onFiltered(filteredItems) {},

    refresh() {
      this.$refs['facultyTable'].refresh();
    },

    loadAll(callback) {
      axios
        .get(`/education/faculty`)
        .then(res => {
          var arr = res.data;
          this.totalRows = res.data.length;

          if (callback) {
            callback(arr || []);
          }

        })
        .catch(err => {
          this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('PAGES.EDUCATIONS.UNABLE_LIST_FACULTY'));
          console.error('pagination get error', err);
        });

    },

    ajaxPagination: function(ctx, callback) {
      const vm = this;
      let params = {
        pageNo: ctx.currentPage,
        pageSize: ctx.perPage,
        flag: 0,
        po: vm.searchOrderNumber,
        styNum: vm.searchStyleNumber
      };

      this.loadAll(callback);

      return null;
    },

    onSortChanged(sortChanged) {

      if (sortChanged.sortBy === '') {
        this.orderBy = undefined;
      }
      else {
        this.orderBy = {

        };

        this.orderBy[sortChanged.sortBy] = sortChanged.sortDesc ? 'desc' : 'asc';
      }
    },

  },
  data() {
    return {
      totalRows: 0,
      filters: {
        text: ''
      },
      perPage: 100,
      currentPage: 1,

      headers: [
        {
          key: 'id',
          label: 'ID',
          // thClass: 'w-165px',
          // tdClass: 'w-165px',
          sortable: false
        },

        {
          key: 'name',
          label: this.$t('PAGES.EDUCATIONS.FACULTY_NAME'),
          thClass: 'w-165px',
          tdClass: 'w-165px',
          sortable: false
        },

        {
          key: 'actions',
          label: this.$t('COMMON.ACTIONS'),
        }

      ],
      list: []
    };
  }
};
</script>
